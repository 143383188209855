@import "./base.less";
@import "~@brainhubeu/react-carousel/lib/style.css";

.container {
  width: 100%;
  align-self: center;
  margin: auto;
}

html,
body {
  margin: 0;
}

body {
  width: 100%;
  overflow-x: hidden;
  font-family: @font-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: @bg-body;
  color: @text-color;

  #root,
  .app {
    width: 100%;
    height: 100%;
  }

  h1,
  h2 {
    letter-spacing: -0.04em;
  }

  h3,
  h4,
  h5,
  h6 {
    letter-spacing: -0.03em;
  }

  .ant-card {
    border-radius: 8px;
  }
  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-horizontal > .ant-menu-item-selected {
    border-bottom: none !important;
  }
  .ant-menu-horizontal > .ant-menu-item:hover {
    color: @primary-color;
    border-bottom: none !important;
  }

  .mobile-container {
    min-height: 70vh;
  }

  .content {
    background-color: transparent;
    overflow-y: auto;
    overflow-x: hidden;

    .content-box {
      background: white;
      padding: 30px 16px;
      margin: 10px;
      border-radius: 4px;

      @media (min-width: @content-width) {
        max-width: @content-width - 40px;
        margin: 20px auto;
        padding: 40px 24px;
      }
    }
  }
}

.App {
  text-align: center;
}
.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.image {
  width: 100%;
  text-align: center;
  margin: 0 auto 30px;

  &.logo {
    max-width: 160px;
  }

  img {
    width: 100%;
    height: auto;
  }
}

h2 {
  margin: 0 0 10px;
  font-size: 48px;
  font-weight: 600;
}

h3 {
  margin: 0 0 10px;
  font-size: 28px;
  font-weight: 600;
}
h4 {
  margin: 0 0 10px;
  font-size: 28px;
  font-weight: 600;
}

.text-small {
  font-size: 0.8em;
}

.ant-btn {
  height: 40px;
  border-radius: 4px;
  padding: 0px 18px;
  font-weight: 600;
  font-family: @font-body;
  border-color: #000;
  color: #000;
  text-transform: uppercase;
}
.ant-btn.ant-btn-sm {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 2px;
}
.ant-btn-primary {
  color: #fff;
  background-color: @primary-color;
  border-color: @primary-color;
  border: none;
}
.ant-btn-primary:hover {
  background-color: @primary-color;
  border-color: @primary-color;
}
.ant-btn-primary:focus {
  background-color: @primary-color;
  border-color: @primary-color;
}

.img-responsive {
  width: 100%;
  max-width: 100%;
}

.error-message,
.success-message {
  margin-top: 20px;
  margin-bottom: 20px;
}

.validation-error {
  color: #ff4d4f;
  margin-top: 4px;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.ant-alert {
  margin-bottom: 20px;
  .ant-alert-message {
    font-weight: 600;
  }
}
.ant-form-item-label > label {
  height: auto !important;
  font-weight: 600;
}
.ant-form-item {
  margin-bottom: 20px;

  .ant-input {
    background-color: #f5f5f8;
    border-color: transparent;
    border-radius: 4px;
  }
  input.ant-input {
    height: 50px;
  }
  .ant-input:focus,
  .ant-input-focused {
    border-color: @primary-color;
    border-width: 1px !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.ant-upload-wrapper {
  margin: 20px 0;
  display: block;
}
.Carousel__arrows {
  font-size: 24px;
  padding: 12px;
  border: none;
  overflow: hidden;
  outline: 0;
  line-height: 0;
  background-color: #fff;
  border: 1px solid #f2f2f7;
  position: absolute;
  border-radius: 50%;
  cursor: pointer;
}
.Carousel__arrows span {
  border-color: #000;
}
.Carousel__arrowLeft {
  left: -10px;
}
.Carousel__arrowRight {
  right: -10px;
}
.Carousel__arrows:hover:enabled {
  border: 1px solid @primary-color;
  background-color: @primary-color;
  color: #fff;
}

.project-link {
  float: right;
  font-size: 16px;
  margin-top: 10px;
}
.ant-avatar {
  max-width: 100%;
}

.btn-load-more {
  margin-top: 20px;
}

.custom-slider {
  width: 100%;
  position: relative;
  > div:nth-child(1) {
    width: 100%;
  }
  .slider-arrows {
    position: absolute;
    width: 100%;
    top: 100px;
    right: 0;
  }
  .carousel .slide {
    min-width: 100%;
    padding-left: 12px;
    padding-right: 0;
    position: relative;
    text-align: unset;
    background: none;
  }
}
.custom-message {
  z-index: 99999;
  position: fixed;
  top: 100px;
  right: 20px;
  .custom-message-custom-content {
    background: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    .anticon {
      margin-right: 10px;
    }
  }
  .custom-message-notice {
    margin-bottom: 10px;
  }
}

.custom-message-success {
  color: #fff !important;
  background: #00c851 !important;
}
.custom-message-error {
  color: #fff !important;
  background: #ff4444 !important;
}

.am-tabs-tab-bar-wrap {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 60px;
  background: #fbfbfd;

  .tab-header {
    text-align: center;
    line-height: 25px;
    font-size: 12px;
    color: #aeaeb2;

    svg {
      font-size: 26px;
      display: block;
      margin: 0 auto !important;
    }
  }
  .am-tabs-default-bar-tab {
    height: auto;
    line-height: unset;
  }
  .am-tabs-default-bar-tab-active {
    .tab-header {
      color: @primary-color;
    }
  }
}
.ant-select-selection-item {
  font-weight: 500;
}

.ant-btn.ant-btn-sm {
  height: 30px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 4px;
}
.ant-btn-danger {
  border: none;
  color: #fff;
}
.ant-btn.ant-btn-sm.ant-btn-icon-only {
  padding: 0;
  width: 30px;
}
.ant-picker {
  width: 100%;
  height: 50px;
}
.ant-select {
  .ant-select-selection-search-input,
  .ant-select-selector {
    height: 50px !important;
  }
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    line-height: 50px !important;
  }
}

.ant-select-item.ant-select-item-option-selected {
  background-color: #a5a5a8 !important;
}

.filter-row {
  margin-bottom: 20px;
  justify-content: space-between;

  .ant-btn,
  .ant-input {
    height: 50px;
  }
}

.filter-menu {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;

  > div {
    flex: 1;
  }
}

.address-list-items {
  span {
    display: block;
  }
}

.badge {
  padding: 5px 6px;
  border-radius: 6px;

  &.core_committee {
    background-color: #2ecc71;
    color: #fff;
  }

  &.convener {
    background-color: #e67e22;
    color: #fff;
  }
  &.sub_convener {
    background-color: #3498db;
    color: #fff;
  }

  &.district_convener {
    background-color: #ffc107;
    color: #fff;
  }
  &.district_sub_convener {
    background-color: #673ab7;
    color: #fff;
  }
  &.taluka_convener {
    background-color: #795548;
    color: #fff;
  }
  &.taluka_sub_convener {
    background-color: #3f51b5;
    color: #fff;
  }

  &.town_convener {
    background-color: #ff5722;
    color: #fff;
  }
  &.town_sub_convener {
    background-color: #9c27b0;
    color: #fff;
  }

  &.ward_convener {
    background-color: #0277bd;
    color: #fff;
  }
  &.ward_sub_convener {
    background-color: #009688;
    color: #fff;
  }

  &.society_convener {
    background-color: #ff9800;
    color: #fff;
  }
  &.society_sub_convener {
    background-color: #4caf50;
    color: #fff;
  }
}

.d-none {
  display: none !important;
}

form {
  h4 {
    font-size: 20px !important;
  }
}

.list-item {
  margin-top: 20px;
  > p {
    font-weight: 600;
  }
}

@media screen and (max-width: 768px) {
  body {
    .ant-layout,
    .ant-layout-footer {
      background: #fff;
    }
    .container {
      padding: 0 15px;
    }
    .ant-tabs-mobile {
      overflow: initial;
    }
  }
}

.ant-pagination {
  .ant-pagination-options {
    .ant-select .ant-select-selection-search-input,
    .ant-select .ant-select-selector {
      height: 35px !important;
    }
    .ant-select .ant-select-selection-item,
    .ant-select .ant-select-selection-placeholder {
      line-height: 35px !important;
    }
  }
}

.list-item table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
  th,
  td {
    text-align: left;
    padding: 8px;
  }
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  th {
    background-color: #4caf50;
    color: white;
  }
}

@primary-color: #672C2C;@secondary-color: #672C2C;@title-color: #022334;@text-color: #000;