.question-form {
  .ant-upload-select-picture {
    margin: 20px 0;
  }
  .ant-radio-group-outline {
    margin-top: 20px;
  }
  .ant-upload-list-item-card-actions button {
    border: none;
  }
}

.ant-table-expanded-row {
  p {
    margin: 0 0 4px;
  }
}

@primary-color: #672C2C;@secondary-color: #672C2C;@title-color: #022334;@text-color: #000;