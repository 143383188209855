@import "../base.less";

.loading {
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);

  .anticon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 40px;
  }
}

@primary-color: #672C2C;@secondary-color: #672C2C;@title-color: #022334;@text-color: #000;