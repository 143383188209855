.auth-container {
  min-height: 280px;
  margin-top: 80px;
  margin-bottom: 30px;

  .auth-card {
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
    padding: 10px;
    margin: 100px auto 0;
    width: 100%;
    max-width: 400px;

    .login-bg {
      height: 100%;
      border-radius: 8px;
      padding: 30px 25px;
      p {
        color: #fff;
        margin-top: 20px;
      }
    }

    .logo {
      text-align: center;
      margin: 0px auto 40px;
      > img {
        width: 180px;
      }
    }
  }

  .txt-heading {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .form-container {
    display: flex;
    justify-content: center;
  }

  .page-login {
    .ant-btn-lg {
      font-weight: 700;
      font-size: 12px;
    }

    .login-label label {
      font-weight: 700;
    }

    .login-input {
      background-color: #f8f8f8;
    }

    .ant-input:placeholder-shown {
      color: @text-color;
    }
    .ant-btn {
      margin-top: 30px;
    }
  }
}

@primary-color: #672C2C;@secondary-color: #672C2C;@title-color: #022334;@text-color: #000;