.coming-soon-container {
  margin-top: 50px;
  padding: 60px 0 150px;
  text-align: center;
  background: #fff;

  h3 {
    font-weight: 300;
  }
  button {
    margin-top: 40px;
  }
}

@primary-color: #672C2C;@secondary-color: #672C2C;@title-color: #022334;@text-color: #000;