@primary-color: #672c2c;
@secondary-color: #672c2c;
@light-primary-color: lighten(@primary-color, 10%);
@dark-primary-color: darken(@primary-color, 10%);
@bg-body: #fff;
@bg-content: white;
@error-color: #f0bebd;
@success-color: #d7f1c9;

@title-color: #022334;
@font-title: "Proza Libre", sans-serif;
@font-body: "system-ui", "Inter", "Roboto", sans-serif;

@soft-color: lighten(@text-color, 20%);

@small: 375px;
@mobile: 767px;

@content-width: 1000px;

@mixin clearfix() {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
