#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.layout {
  min-height: 100vh;

  .layout-header {
    display: flex;
    padding: 12px;
    justify-content: flex-end;
  }

  aside {
    .ant-layout-header {
      padding: 0 20px;
      background-color: #fff;
      .logo {
        > img {
          width: 150px;
        }
      }
    }
    .ant-divider {
      border-top: 1px solid #fff;
    }
    .ant-menu {
      margin-top: 50px;
    }
    .ant-menu-item {
      height: 40px;
      line-height: 40px;
      margin-bottom: 10px;
    }
  }

  .content-container {
    padding: 24px;
    min-height: 360;
    margin: 0 16px;
  }

  .footer-container {
    text-align: center;
    .copy-text {
      margin-top: 10px;
      text-align: right;
      color: #646e85;
    }
  }
}

@primary-color: #672C2C;@secondary-color: #672C2C;@title-color: #022334;@text-color: #000;